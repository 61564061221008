<template>
    <div>
        <div class="topMenu">
            <div class="mnuArea" @mouseenter="menu.showSubMenu">
                <div class="mnuText">파일</div>
                <div class="mnuText">모드(기능)</div>
                <div class="mnuText">관리자 설정</div>
                <div class="mnuText">도움말</div>
                <div class="mnuRight">로그아웃</div>
            </div>
            <div class="subMenu" v-show="menu.flagShowSubMenu" @mouseleave="menu.hideSubMenu">
                <div class="boxMenu">
                    <ul class="subMneuList">
                        <li>신규 파일 열기</li>
                        <li @click="menu.loadData()">기존 파일 불러오기</li>
                        <li @click="menu.saveData()">저장하기</li>
                        <li>파일 닫기</li>
                    </ul>
                </div>
                <div class="boxMenu">
                    <ul class="subMneuList">
                        <li>목차</li>
                        <li>검색</li>
                        <li>툴민</li>
                        <li>스토리라인</li>
                        <li>테이블 데이터</li>
                    </ul>
                </div>
                <div class="boxMenu">
                    <ul class="subMneuList">
                        <li>사용자 관리</li>
                        <li>언어</li>
                    </ul>
                </div>
                <div class="boxMenu">
                    <ul class="subMneuList">
                        <li>시작하기</li>
                    </ul>
                </div>
            </div>
        </div>
        <router-view/>
    </div>
</template>

<script>
// @ is an alias to /src
import { reactive } from 'vue'
import axios from '@/plugins/axios.js';
import { useRouter } from 'vue-router';

export default {
    name: 'home',
    components: {
    },
    setup() {
        const router = useRouter();
        const menu = reactive({
            flagShowSubMenu: false,
            showSubMenu: () => {
                menu.flagShowSubMenu = true;
            },
            hideSubMenu: () => {
                menu.flagShowSubMenu = false;
            },
            saveData: () => {
                let content = localStorage.getItem("ed_content")
                content = (content==null?"":content);
                let node = JSON.parse(localStorage.getItem("ed_node"))
                let lastid = localStorage.getItem("lastid");
                let params = {idx:1, content: content, json : {node: node, lastid:lastid}};
                axios.post("/api/document/save", params).then((res) => {
                    console.info(res.data);
                });
            },
            loadData: () => {
                axios.get("/api/document/load/1").then((res) => {
                    localStorage.setItem("ed_content", res.data.content);
                    localStorage.setItem("ed_node", JSON.stringify(res.data.json.node));
                    localStorage.setItem("lastid", res.data.json.lastid);
                    router.go(0);
                });

            }

        });
        return { menu };
    }

}
</script>
<style lang="scss" scoped>
.topMenu {
    height: 50px;
    display: block;
    background-color: #ddd;
}
.mnuArea {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background-color: #222222;
    color: #d9d9d9;
    padding: 0px 20px;
    div.mnuText {
        width: 150px;
        font-weight: 400;
        float: left;
        text-align: center;
    }
    div.mnuRight {
        float: right;
        text-align: center;
    }
}
.subMenu {
    z-index: 1000;
    position: fixed;
    height: 170px;
    padding: 0px 20px;
    top: 50px;
    left:0;
    right:0;
    background-color: #222222;
    border-bottom: 1px solid #777;
    .boxMenu {
        width: 150px;
        height: 150px;
        text-align: center;
        border-right: 1px solid #555;
        float:left;
    }
    .subMneuList {
        li {
            height: 30px;
            color: #d9d9d9;
            cursor: pointer;
            &:hover {
                font-weight: bold;
            }
        }
    }
}
.viewer {
    border:1px solid #ddd;
    outline: none;
    padding: 5px;
    margin: 5px;
}
</style>